/* MONSTER VIEW */
@media (min-width: 1441px) {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /* NAVBAR CSS */
  .header-container {
    font-weight: 400;
    position: fixed;
    margin-top: 21px;
    z-index: 1;
  }

  .nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    min-height: 0vw;
    font-size: clamp(1.2rem, 1.6vw, 22px);
    font-size: 26px;
    margin-top: 0px;
    left: 2.45%;
    right: 2.45%;
  }

  .middle-nav-element {
    cursor: default;
  }

  .right-nav-element {
    cursor: pointer;
  }

  .flex-container {
    list-style-type: none;
  }

  .list-items a {
    color: inherit;
    text-decoration: none;
  }

  /* HIDE AND MODIFY ELEMENTS */
  .project-title {
    display: none;
  }
  .smaller-screen-view {
    display: none;
  }

  .hide-nav-element {
    cursor: none;
  }

  /* GRID CSS */
  .image-section {
    position: absolute;
    display: flex;
    margin-top: 75px;
    z-index: 0;
    width: 100%;
  }
  .grid-container {
    display: grid;
    grid-gap: clamp(20px, 2vw, 28px);
    margin: 0% 2.45% 2.45% 2.45%;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .justify-image-frame {
    width: 50%;
  }

  .grid-container img {
    height: 100%;
    width: 100%;
  }

  .grid-item {
    height: 100%;
    cursor: pointer;
    position: relative;
  }

  .hide-nav-element {
    cursor: none;
  }

  /* HOVER */
  .remove-padding p {
    margin: 8px;
  }

  .hover-text-style {
    padding: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  .hover-animation {
    position: absolute;
    top: 0;
    max-height: 0;
    overflow: hidden;
    color: white;
    font-size: 22px;
    background-color: #292929;
  }
  .grid-item:hover .hover-animation {
    position: absolute;
    top: 0;
    color: white;
    max-height: 100%;
    width: 100%;
    transition: max-height 0.8s;
    background-color: #333333;
  }

  .info-grid-parent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .info-overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    overflow: scroll;
  }

  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-weight: 400;
    font-family: Helvetica;
    color: white;
    background-color: #292929;
  }

  .main-info-grid-container {
    font-weight: 400;
    cursor: default;
    font-size: 28px;
    height: 100%;
    padding: 1.7% 2.5% 1.7% 2.5%;
    line-height: 1.5em;
  }

  .info-grid-container {
    display: flex;
    flex-wrap: wrap;
  }

  .info-item-1 {
    width: 100%;
    padding-bottom: 20px;
    padding-bottom: 1.45%;
  }

  .info-item-5 {
    width: 50%;
  }

  .info-item-2 {
    width: 47%;
  }

  .info-item-2,
  .info-item-3 {
    padding-bottom: 1.45%;
  }
  .info-item-3 {
    padding-left: 14px;
    padding-left: 4.02%;
    width: 50%;
  }
  .info-item-6 {
    width: 50%;
    padding-left: 1.02%;
  }

  .info-item-4 {
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  .karel-kranich-element {
    display: inline-block;
    text-decoration: underline;
  }

  .karel-kranich-element:hover {
    text-decoration: transparent;
  }
  .info-item-6 {
    width: 50%;
    padding-left: 1.02%;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }

  .no-underline {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline-two {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline a:hover {
    text-decoration: transparent;
  }
  .no-underline-two:hover {
    text-decoration: transparent;
  }

  .mobile-info-layout {
    display: none;
  }

  /* PROJECT VIEW CSS */
  .smaller-project-view {
    display: none;
  }
  /* buffer loader css */
  .buffer-container {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    max-height: 300px;
    transform: translate(-50%, -50%);
  }

  .overlay-styles {
    z-index: 1000;
    height: 100%;
    width: 100%;
    z-index: 7;
    top: 0;
  }

  /* PROJECT LANDING PICTURE */
  .main-landing-picture-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 22px;
    display: flex;
    backdrop-filter: blur(13px);
    justify-content: center;
  }

  .landing-picture-container {
    width: auto;
    height: 100%;
  }

  .landing-picture {
    max-height: 100%;
    max-width: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .main-description-container {
    position: absolute;
    display: flex;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;
    justify-content: center;
    width: 100%;
  }

  .project-details {
    padding-top: 5.2%;
    font-size: 22px;
    line-height: 22px;
    min-width: 100%;
    min-height: 100%;
  }

  .longer-paragraph-description {
    font-size: 32px;
    line-height: 43px;
  }

  .project-client {
    display: inline-block;
    width: 48%;
  }

  .year-cvi {
    display: inline-block;
  }

  /* PROJECT VIEW PICTURES SECTION */

  .project-view-pictures {
    margin-top: 4.73%;
    line-height: 0;
  }

  .project-picture-size {
    margin-bottom: 110px;
  }
  .project-picture-size img {
    width: 100%;
    height: 100%;
  }
  .project-view-pictures div:last-child {
    margin-bottom: 0px;
  }
}

/* LAPTOP PAGE VIEW 1440PX - 1178PX */
@media (max-width: 1440px) and (min-width: 1178px) {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Helvetica";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
    overflow: unset;
  }

  .switch-wrapper {
    position: relative;
  }
  /* NAVBAR CSS */
  .header-container {
    font-weight: 400;
    position: fixed;
    top: 0;
    margin-top: 21px;
    z-index: 1;
  }

  .nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    min-height: 0vw;
    font-size: clamp(1.2rem, 1.6vw, 22px);
    font-size: 22px;
    left: 2.45%;
    right: 2.45%;
    z-index: 0;
  }

  .middle-nav-element {
    cursor: default;
  }

  .right-nav-element {
    cursor: pointer;
  }

  .flex-container {
    list-style-type: none;
  }

  .list-items a {
    color: inherit;
    text-decoration: none;
  }

  /* HIDE AND MODIFY ELEMENTS */

  .smaller-screen-view {
    display: none;
  }
  .project-title {
    display: none;
  }

  .hide-nav-element {
    cursor: none;
  }

  /* GRID CSS */
  .image-section {
    position: absolute;
    display: flex;
    margin-top: 67px;
    width: 100%;
  }

  .grid-container {
    display: grid;
    /* grid-gap: clamp(20px, 2vw, 28px); */
    column-gap: clamp(20px, 2vw, 28px);
    row-gap: clamp(20px, 1.6vw, 26px);
    margin: 0% 2.45% 2.45% 2.45%;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .grid-item {
    height: 100%;
    width: auto;
    cursor: pointer;
    position: relative;
  }

  .grid-item img {
    max-width: 100%;
  }

  /* HOVER */
  .remove-padding p {
    margin: 8px;
  }

  .hover-text-style {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  .hover-animation {
    position: absolute;
    top: 0;
    max-height: 0%;
    overflow: hidden;
    color: white;
    width: 100%;
    background-color: #292929;
    padding: 0px;
  }
  .grid-item:hover .hover-animation {
    position: absolute;
    top: 0;
    color: white;
    max-height: 100%;
    width: 100%;
    transition: max-height 0.8s;
    background-color: #333333;
  }

  .info-overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    z-index: 232323233232;
    cursor: pointer;
  }

  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-family: Helvetica;
    color: white;

    background-color: #292929;
  }

  .main-info-grid-container {
    cursor: default;
    font-weight: 400;
    font-size: 20px;
    padding: 1.7% 2.5% 1.7% 2.5%;
    line-height: 1.5em;
  }

  .info-grid-container {
    display: flex;
    flex-wrap: wrap;
  }

  .info-item-1 {
    width: 100%;
    padding-bottom: 20px;
    padding-bottom: 1.45%;
  }

  .info-item-5 {
    width: 50%;
  }

  .info-item-2 {
    width: 47%;
  }

  .info-item-2,
  .info-item-3 {
    padding-bottom: 1.45%;
  }
  .info-item-3 {
    padding-left: 14px;
    padding-left: 4.02%;
    width: 50%;
  }
  .info-item-4 {
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  .karel-kranich-element {
    display: inline-block;
    text-decoration: underline;
  }

  .karel-kranich-element:hover {
    text-decoration: transparent;
  }
  .info-item-6 {
    width: 50%;
    padding-left: 1.02%;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }

  .no-underline {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline-two {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline a:hover {
    text-decoration: transparent;
  }
  .no-underline-two:hover {
    text-decoration: transparent;
  }

  .mobile-info-layout {
    display: none;
  }

  /* PROJECT VIEW CSS */

  /* Fetch API loading animation */
  .main-buffer-container {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1;
    width: 100vw;
  }

  .buffer-container {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    max-height: 300px;
    transform: translate(-50%, -50%);
  }

  .overlay-styles {
    z-index: 1000;
    height: 100%;
  }

  /* PROJECT LANDING PICTURE */
  .main-landing-picture-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 1.53%;
    padding: 22px;
    display: flex;
    backdrop-filter: blur(13px);
    justify-content: center;
  }

  .landing-picture-container {
    width: auto;
    height: 100%;
  }

  .landing-picture {
    height: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .smaller-project-view {
    display: none;
  }

  .main-description-container {
    position: absolute;
    display: flex;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 19px;
    justify-content: center;
    width: 100%;
    border: 3px solid transparent;
  }

  .project-details {
    padding-top: 5.2%;
    font-size: 22px;
    line-height: 22px;
  }

  .longer-paragraph-description {
    font-size: 32px;
    line-height: 43px;
  }

  .project-client {
    display: inline-block;
    width: 48%;
  }

  .year-cvi {
    display: inline-block;
  }

  /* PROJECT VIEW PICTURES SECTION */
  .project-view-pictures {
    margin-top: 4.73%;
    line-height: 0;
  }

  .project-picture-size {
    margin-bottom: 110px;
  }
  .project-picture-size img {
    width: 100%;
    height: 100%;
  }
  .project-view-pictures div:last-child {
    margin-bottom: 0px;
  }
}

/* IPAD PAGE VIEW 1177-640  */
@media (max-width: 1177px) and (min-width: 641px) {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
  /* NAVBAR CSS */

  .header-container {
    font-weight: 400;
    position: fixed;
    top: 0;
    margin-top: 21px;
    z-index: 5;
  }

  .nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    min-height: 0vw;
    font-size: clamp(1.2rem, 1.6vw, 22px);
    font-size: 22px;
    margin-top: 0px;
    left: 2.45%;
    right: 2.45%;
  }

  .middle-nav-element {
    cursor: default;
  }

  .right-nav-element {
    cursor: pointer;
  }

  .list-items a {
    color: inherit;
    text-decoration: none;
  }

  .middle-nav-element {
    cursor: default;
  }

  .right-nav-element {
    cursor: pointer;
  }

  /* HIDE AND MODIFY ELEMENTS */
  .smaller-screen-view {
    display: none;
  }
  .project-title {
    display: none;
  }

  /* GRID CSS */
  .image-section {
    position: absolute;
    margin-top: 67px;
    min-width: 100%;
    z-index: 0;
  }

  .grid-container {
    display: grid;
    /* grid-gap: clamp(13px, 2vw, 28px); */
    column-gap: clamp(13px, 2vw, 28px);
    row-gap: clamp(8px, 1.5vw, 28px);
    margin: 0% 2.45% 2.45% 2.45%;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    height: 100%;
    width: auto;
    cursor: pointer;
    position: relative;
    max-height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .optional-size {
    max-width: 47.7%;
  }

  .grid-item img {
    max-width: 100%;
    /* height:100%; */
  }

  .justify-image-frame {
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }

  /* HOVER */
  .remove-padding p {
    margin: 8px;
  }
  .hover-text-style {
    padding: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  .hover-animation {
    position: absolute;
    top: 0;
    max-height: 0;
    overflow: hidden;
    color: white;
    width: 100%;
    background-color: #292929;
  }
  .grid-item:hover .hover-animation {
    position: absolute;
    top: 0;
    color: white;
    max-height: 100%;
    width: 100%;
    transition: max-height 0.8s;
    background-color: #333333;
  }

  .info-grid-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .info-overlay-styles {
    position: fixed;
    top: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 232323233232;
  }
  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-weight: 300;
    font-family: Helvetica;
    color: white;
    background-color: #292929;
  }

  .main-info-grid-container {
    font-size: 20px;
    line-height: 1.6em;
    font-weight: 400;
    padding: 1.7% 2.5% 1.7% 2.5%;
  }

  .info-grid-container {
    display: flex;
    flex-wrap: wrap;
  }

  .info-item-1 {
    width: 100%;

    padding-bottom: 1.45%;
  }

  .info-item-5 {
    width: 50%;
  }

  .info-item-2 {
    width: 47%;
  }

  .info-item-2,
  .info-item-3 {
    padding-bottom: 1.45%;
  }
  .info-item-3 {
    padding-left: 14px;
    padding-left: 4.02%;

    width: 50%;
  }
  .info-item-6 {
    width: 50%;
    /* text-decoration: underline; */
    padding-left: 1.02%;
  }

  /* break */
  .info-item-4 {
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  .karel-kranich-element {
    display: inline-block;
    text-decoration: underline;
  }

  .karel-kranich-element:hover {
    text-decoration: transparent;
  }
  .info-item-6 {
    width: 50%;
    padding-left: 1.02%;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }

  .no-underline {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline-two {
    display: inline-block;
    text-decoration: underline;
  }

  .no-underline a:hover {
    text-decoration: transparent;
  }
  .no-underline-two:hover {
    text-decoration: transparent;
  }

  .mobile-info-layout {
    display: none;
  }

  /* end */
  /* PROJECT VIEW CSS */

  .overlay-styles {
    z-index: 42;
  }
  /* PROJECT LANDING PICTURE */
  .main-landing-picture-container {
    width: 100%;
    height: 100vh;
    padding: 22px;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(13px);
  }

  .landing-picture-container {
    width: auto;
    height: 100%;
  }

  .landing-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobile-info-layout {
    display: none;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .smaller-project-view {
    display: none;
  }

  .main-description-container {
    position: absolute;
    display: flex;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 19px;
    justify-content: center;
    width: 100%;
    border: 3px solid transparent;
  }

  .project-details {
    padding-top: 5.2%;
    font-size: 22px;
    line-height: 22px;
  }

  .longer-paragraph-description {
    font-size: 32px;
    line-height: 43px;
  }

  .project-client {
    display: inline-block;
    width: 50%;
    width: 48%;
    padding-right: 4%;
  }

  .year-cvi {
    display: inline-block;
  }

  /* PROJECT VIEW PICTURES SECTION */
  .project-view-pictures {
    margin-top: 4.73%;
    line-height: 0;
  }

  .project-picture-size {
    margin-bottom: 110px;
  }
  .project-picture-size img {
    width: 100%;
    height: 100%;
  }
  .project-view-pictures div:last-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 800px) and (min-width: 641px) {
  .smaller-screen-view {
    display: none;
  }
  .info-overlay-styles {
    position: fixed;
    top: 0;
    overflow: scroll;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 232323233232;
  }

  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-weight: 400;
    font-family: Helvetica;
    color: white;
    background-color: #292929;
  }

  .main-info-grid-container {
    font-size: clamp(0.6rem, 1.5vw, 0.926em);
    font-weight: 400;
    line-height: clamp(1.2em, 4vw, 1.4em);

    line-height: 30px;
    line-height: 1.92em;

    line-height: clamp(1.4em, 4vw, 1.5em);

    font-size: 20px;
    line-height: 1.6em;
    padding: 1.7% 2.5% 1.7% 2.5%;
  }

  .info-item-1 {
    width: 100%;
    margin-bottom: 6px;
  }

  .info-item-5 {
    width: 50%;
  }

  .info-item-2 {
    width: 47%;
  }

  .info-item-2,
  .info-item-3 {
    margin-bottom: 6px;
  }
  .info-item-3 {
    padding-left: 4.02%;
    width: 50%;
  }
  .info-item-6 {
    width: 50%;
    /* text-decoration: underline; */
    padding-left: 1.02%;
  }
}

/* MINI-IPAD PAGE VIEW 640-375  */
@media (max-width: 640px) and (min-width: 376px) {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }

  /* HIDE AND MODIFY ELEMENTS */
  .bigger-screen-view {
    display: none;
  }
  .hide-nav-element {
    display: none;
  }

  /* NAVBAR CSS */

  .header-container {
    font-weight: 400;
    z-index: 3;
  }

  .nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    left: 15px;
    right: 15px;
    z-index: 4;
    list-style-type: none;
  }

  .henri-kutsar-element {
    line-height: 4.6vh;
    font-size: clamp(22px, 5vw, 30px);
    font-size: clamp(18px, 5vw, 24px);
    font-size: 24px;
    line-height: 27.6px;
    min-height: 0vw;
  }

  .info-contact-element {
    font-size: clamp(12px, 3.5vw, 16px);
    line-height: 3vh;
    font-size: 16px;
    line-height: 18.4px;
    cursor: pointer;
    min-height: 0vw;
  }

  .list-items a {
    color: inherit;
    text-decoration: none;
  }

  /* ADDED TITLES & remove dang.html padding  */
  .remove-padding p {
    margin: 0px;
  }

  .project-title {
    display: block;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 19px;
  }

  /* GRID CSS */
  .image-section {
    position: absolute;
    z-index: 1;
    padding: 0px 2.45% 0px 2.45%;
    padding: 0px 15px 0px 15px;
    padding-top: 65px;
  }

  .grid-container {
    display: block;
    grid-row-gap: 2.9%;
    grid-column-gap: 2%;
    margin: 0px 0px 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    height: 100%;
    width: auto;
    cursor: pointer;
    position: relative;
    max-height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .grid-item img {
    max-width: 100%;
  }

  .justify-image-frame {
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;
  }

  .info-overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 2323232332322;
    overflow: scroll;
  }

  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-weight: 400;
    font-family: Helvetica;
    color: white;
    background-color: #292929;

    padding: 15px;
    cursor: default;
  }

  .main-info-grid-container {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }

  .hide-hover {
    display: none;
  }

  .info-item-1 {
    margin-bottom: 18px;
  }

  .karel-kranich-element {
    display: inline-block;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }

  .karel-kranich-element:hover {
    text-decoration: transparent;
  }

  .info-item-6 a {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
    display: inline-block;
  }

  .info-item-6 a:hover {
    text-decoration: transparent;
  }

  .info-item-5 {
    width: 50%;
  }

  .info-item-2,
  .info-item-3 {
    margin-bottom: 18px;
  }

  .mobile-info-layout {
    display: flex;
  }

  .computer-info-layout {
    display: none;
  }

  /* PROJECT VIEW CSS */
  .larger-project-view {
    display: none;
  }

  /* Fetch API loading animation */
  .main-buffer-container {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1999;
    width: 100vw;
  }

  .buffer-container {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    max-height: 300px;
    transform: translate(-50%, -50%);
  }

  .project-nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 43;
    width: 100%;
  }

  .project-henri-kutsar-element {
    font-size: 24px;
    line-height: 27.6px;
    cursor: pointer;
    min-height: 0vw;
  }

  .project-info-contact-element {
    font-size: clamp(12px, 3.5vw, 16px);
    line-height: 3vh;
    font-size: 16px;
    line-height: 18.4px;
    cursor: pointer;
    min-height: 0vw;
  }

  .project-list-items a {
    color: inherit;
    text-decoration: none;
  }

  /* OVERLAY STYLES */
  .overlay-styles {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: unset;
    z-index: 3;
  }

  /* PROJECT LANDING PICTURE */
  .main-landing-picture-container {
    padding-top: 65px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    z-index: 0;
  }

  .landing-picture-container {
    width: auto;
    height: 100%;
  }
  .landing-picture {
    width: 100%;
    height: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .main-description-container {
    position: relative;
    display: flex;
    padding-left: 2.45%;
    padding-right: 2.45%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .descriptions-container {
    font-style: normal;
    font-weight: 400;
    height: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .project-details {
    display: block;
    padding-top: 4.2%;
    padding-top: 15px;
    font-size: 14px;
    font-size: 17px;
    line-height: 21px;
  }

  .year-cvi {
    margin: auto;
  }

  .longer-paragraph-description {
    padding-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }

  /* PROJECT VIEW PICTURES SECTION */
  .project-view-pictures {
    line-height: 0;
  }
  .project-picture-size {
    margin-bottom: 45px;
  }
  .project-picture-size img {
    width: 100%;
    height: 100%;
  }

  .project-view-pictures div:last-child {
    margin-bottom: 0px;
  }
}

/* MOBILE PAGE VIEW 640-375  */
@media (max-width: 375px) {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
  /* NAVBAR CSS */

  .header-container {
    font-weight: 400;
  }

  /* HIDE AND MODIFY ELEMENTS */
  .bigger-screen-view {
    display: none;
  }
  .hide-nav-element {
    display: none;
  }

  .henri-kutsar-element {
    font-size: 19px;
    font-size: clamp(16px, 5vw, 22px);
    min-height: 0vw;
    font-size: 16px;
    line-height: 18.4px;
    line-height: 4.6vh;
    font-size: 20px;
    line-height: 27.6px;
    cursor: pointer;
  }

  .info-contact-element {
    font-size: clamp(10px, 4vw, 14px);
    line-height: 3vh;
    min-height: 0vw;
    cursor: pointer;
    font-size: 14px;
    line-height: 18.4px;
  }

  .nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    left: 15px;
    right: 15px;
    z-index: 2000;
    list-style-type: none;
  }

  .list-items a {
    color: inherit;
    text-decoration: none;
  }

  .project-nav {
    color: #000000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 43;
    width: 100%;
  }

  .project-henri-kutsar-element {
    cursor: pointer;
    min-height: 0vw;
    font-size: 20px;
    line-height: 27.6px;
  }

  .project-info-contact-element {
    cursor: pointer;
    min-height: 0vw;
    font-size: 14px;
    line-height: 18.4px;
  }

  .project-list-items a {
    color: inherit;
    text-decoration: none;
  }

  /* ADDED TITLES */
  .project-title {
    display: block;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 19px;
  }

  /* GRID CSS */
  .image-section {
    position: absolute;
    margin-top: 0px;
    margin-top: 17%;
    z-index: 0;
    padding: 0px 4% 0px 4%;
    padding: 0px 15px 0px 15px;
  }

  .grid-container {
    display: block;
    grid-gap: 1.7em;
    margin: 0px 0px 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    height: 100%;
    width: auto;
    cursor: pointer;
    position: relative;
    max-height: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .optional-size {
    max-width: 47.7%;
  }

  .grid-item img {
    max-width: 100%;
  }

  .info-grid-parent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 232323233232;
  }

  .info-overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 2323232332322;
  }

  .info-content-styles {
    width: 100vw;
    height: 100%;
    font-weight: 400;
    font-family: Helvetica;
    color: white;
    background-color: #292929;
  }

  .main-info-grid-container {
    display: block;
    font-size: 14px;
    line-height: 2.9vh;
    line-height: 20px;
    padding: 15px;
    cursor: default;
    font-weight: 400;
  }
  /* HIDE HOVER & REMOVE PADDING FROM dang.html*/
  .remove-padding p {
    margin: 0px;
  }
  .hide-hover {
    display: none;
  }

  .info-item-1 {
    margin-bottom: 17.5px;
  }

  .info-item-2,
  .info-item-3 {
    margin-bottom: 17.3px;
  }

  .karel-kranich-element {
    display: inline-block;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1.5px;
  }

  .karel-kranich-element:hover {
    text-decoration: transparent;
  }

  .info-item-6 a {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1.5px;
  }

  .info-item-6 a:hover {
    text-decoration: transparent;
  }

  .mobile-info-layout {
    display: block;
  }

  .computer-info-layout {
    display: none;
  }

  /* PROJECT VIEW CSS */
  .larger-project-view {
    display: none;
  }

  /* Fetch API loading animation */
  .main-buffer-container {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 19999;
    width: 100vw;
  }

  .buffer-container {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    max-height: 300px;
    transform: translate(-50%, -50%);
  }

  /* OVERLAY STYLES */
  .overlay-styles {
    position: absolute;
    max-width: 100%;
    z-index: 1000;
  }
  /* PROJECT-VIEW NAVBAR */

  /* PROJECT LANDING PICTURE */
  .main-landing-picture-container {
    padding-top: 65px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
  }

  .landing-picture-container {
    width: auto;
    height: 100%;
    line-height: 0%;
  }

  .landing-picture {
    max-width: 100%;
    max-height: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .main-description-container {
    position: relative;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    justify-content: center;
    background-color: rgba(253, 241, 244);
    width: 100%;
  }

  /* PROJECT VIEW - TWO LINE DESCRIPTION SECTION */
  .project-details {
    display: block;
    padding-top: 15px;
    font-size: 14px;
    line-height: 18px;
  }

  .year-cvi {
    margin: auto;
  }

  .longer-paragraph-description {
    padding-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
  }

  /* PROJECT VIEW PICTURES SECTION */
  .project-view-pictures {
    line-height: 0;
  }
  .project-picture-size {
    margin-bottom: 45px;
  }
  .project-picture-size img {
    width: 100%;
    height: 100%;
  }
  .project-view-pictures div:last-child {
    margin-bottom: 0px;
  }
}
